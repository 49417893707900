import { formatPrice } from '../../../utils/formatPrice';
import axios from 'axios';
import { openDynamicBanner } from '@/components/dynamic-banner';
import PageHandlersService from '@/utils/page-handlers-service';

interface IGetCartResponse {
    success: boolean;
    data: {
        contentHtml: string;
        totalPrice: number;
        totalCount: number;
    };
    message: string;
}

interface IProductAdditionResponse {
    success: boolean;
    message: string;
    data: {
        contentHtml: string;
        totalPrice: number;
        totalCount: number;
    };
}

const htmlElement = document.querySelector('html');
const langValue = htmlElement?.getAttribute('lang');
let errorMessage = '';

if (langValue === 'ru') {
    errorMessage = 'Произошла ошибка при обновлении товаров. Попробуйте через пару минут.';
} else {
    errorMessage = 'An error occurred while updating the products. Please try again in a few minutes.';
}

const axiosAbortController = new AbortController();

const customEventsMap = new Map<number, Event>();

const productsContainer = document.querySelector('.js-hp-products-container');

const pageHandlers = new PageHandlersService();
const productHandlers = new PageHandlersService();

const cartButtons = document.querySelectorAll<HTMLElement>('.js-cart-btn');
const cartPopup = document.querySelector('.js-hp-cart');
const closePopupButton = document.querySelector('.js-hp-cart-close');
const productCounters = document.querySelectorAll<HTMLElement>('.js-hp-cart-counter');
const totalAmountElement = document.querySelector<HTMLElement>('.js-hp-cart-total-value');

const cartNotificationsContainer = document.querySelector('.js-cart-notifications-container');
const cartNotificationExampleElement = document.querySelector('.js-cart-notification-example');

let handlePreviousNotificationClose: (() => void) | null = null;

const updateProductsHandlers = () => {
    productHandlers.clear();

    if (productsContainer && totalAmountElement) {
        const products = productsContainer.querySelectorAll<HTMLElement>('.js-hp-cart-product');

        let currentRemovingProductsCount = 0;

        products.forEach((productElement) => {
            const priceElement = productElement.querySelector<HTMLElement>('.js-hp-cart-product-amount');
            const priceValue = productElement.dataset.price;
            let priceAmountValue = priceElement?.innerText && parseInt(priceElement?.innerText.split(' ').join(''));

            const counterMinus = productElement.querySelector('.js-hp-cart-product-counter-minus');
            const counterValueElement = productElement.querySelector<HTMLElement>('.js-hp-cart-product-counter-value');
            const counterPlus = productElement.querySelector('.js-hp-cart-product-counter-plus');

            const removeBtn = productElement.querySelector('.js-cart-product-remove-btn');
            const restoreBtn = productElement.querySelector('.js-hp-cart-product-restore-btn');

            const productId = +(productElement.dataset?.id ?? -1);

            if (productId === -1) {
                return;
            }

            let sendingProductsQuantity = 0;
            let debounceTimeout: NodeJS.Timeout;
            let isPreviousRequestNotFinished = false;

            const handleCounterChangeClosure = (action: 'increment' | 'decrement') => () => {
                if (!counterValueElement) {
                    return;
                }

                if (action === 'decrement' && counterValueElement?.innerText === '1') {
                    return;
                }

                if (debounceTimeout) {
                    clearTimeout(debounceTimeout);
                }

                if (counterValueElement && priceElement && priceAmountValue && priceValue) {
                    if (action === 'decrement') {
                        sendingProductsQuantity -= 1;

                        counterValueElement.innerText = `${+counterValueElement.innerText - 1}`;

                        priceAmountValue -= +priceValue;
                        priceElement.innerText = `${formatPrice(priceAmountValue)}`;
                    } else {
                        sendingProductsQuantity += 1;

                        counterValueElement.innerText = `${+counterValueElement.innerText + 1}`;

                        priceAmountValue += +priceValue;
                        priceElement.innerText = `${formatPrice(priceAmountValue)}`;
                    }
                }

                debounceTimeout = setTimeout(async () => {
                    if (sendingProductsQuantity === 0) {
                        return;
                    }

                    try {
                        if (isPreviousRequestNotFinished) {
                            axiosAbortController.abort();
                        }

                        isPreviousRequestNotFinished = true;

                        const productAdditionResponse = await axios.post<IProductAdditionResponse>(
                            productElement.dataset.href!,
                            {
                                id: productElement.dataset.id,
                                quantity: sendingProductsQuantity,
                                action: 'update',
                            },
                            {
                                signal: axiosAbortController.signal,
                            },
                        );

                        isPreviousRequestNotFinished = false;

                        if (!productAdditionResponse.data.success) {
                            openDynamicBanner(productAdditionResponse.data.message);
                        } else {
                            sendingProductsQuantity = 0;

                            if (productsContainer && productAdditionResponse.data.data?.contentHtml) {
                                productsContainer.innerHTML = productAdditionResponse.data.data.contentHtml;
                            }
                        }

                        if (currentRemovingProductsCount === 0) {
                            loadCartData();
                        }
                    } catch (error) {
                        sendingProductsQuantity = 0;
                        isPreviousRequestNotFinished = false;

                        openDynamicBanner(errorMessage);

                        if (counterValueElement) {
                            counterValueElement.innerText = `${
                                +counterValueElement.innerText - sendingProductsQuantity
                            }`;
                        }

                        throw error;
                    }
                }, 1000);
            };

            const customEvent = new CustomEvent(`handle-product-count-increment-${productId}`, {
                bubbles: true,
            });

            customEventsMap.set(productId, customEvent);

            const handleMinusClick = handleCounterChangeClosure('decrement');
            const handlePlusClick = handleCounterChangeClosure('increment');

            productHandlers.set(counterMinus, handleMinusClick);
            productHandlers.set(counterPlus, handlePlusClick);
            productHandlers.set(
                document,
                handlePlusClick,
                `handle-product-count-increment-${productId}` as keyof WindowEventMap,
            );

            let productRemoveTimeout: NodeJS.Timeout;

            const handleProductRemove = () => {
                productElement.classList.add('removing');

                currentRemovingProductsCount += 1;

                productRemoveTimeout = setTimeout(async () => {
                    try {
                        const productRemovingResponse = await axios.post<IProductAdditionResponse>(
                            productElement.dataset.href!,
                            {
                                id: productId,
                                action: 'delete',
                            },
                        );

                        if (!productRemovingResponse.data.success) {
                            openDynamicBanner(productRemovingResponse.data.message);
                        } else {
                            const product = productElement.querySelector('.js-hp-cart-product-name');
                            const productName = product?.innerHTML;
                            currentRemovingProductsCount -= 1;
                            productElement.classList.add('removed');

                            //metric event
                            window.dataLayer?.push({
                                ecommerce: {
                                    currencyCode: 'RUB',
                                    remove: {
                                        products: [
                                            {
                                                id: productName,
                                            },
                                        ],
                                    },
                                },
                            });

                            setTimeout(() => {
                                if (currentRemovingProductsCount === 0) {
                                    loadCartData();
                                }
                            }, 400);
                        }

                        if (productRemovingResponse.data.data?.totalCount) {
                            productCounters.forEach((counterElement) => {
                                counterElement.innerText = `${productRemovingResponse.data.data.totalCount}`;
                            });
                        }

                        if (totalAmountElement && productRemovingResponse.data.data?.totalPrice) {
                            totalAmountElement.innerText = formatPrice(productRemovingResponse.data.data.totalPrice);
                        }
                    } catch (error) {
                        openDynamicBanner(errorMessage);
                        productElement.classList.remove('removing');

                        currentRemovingProductsCount -= 1;

                        throw error;
                    }
                }, 3000);
            };

            const handleProductRestore = () => {
                clearTimeout(productRemoveTimeout);

                productElement.classList.remove('removing');
            };

            productHandlers.set(removeBtn, handleProductRemove);
            productHandlers.set(restoreBtn, handleProductRestore);
        });
    }
};

const loadCartData = async () => {
    if (cartButtons[0]?.dataset.href) {
        try {
            const cartResponse = await axios.get<IGetCartResponse>(cartButtons[0].dataset.href);

            if (cartResponse.data.success) {
                if (productsContainer && cartResponse.data.data.contentHtml) {
                    productsContainer.innerHTML = cartResponse.data.data.contentHtml;
                }
                cartPopup?.classList.remove('empty');

                if (cartResponse.data.data?.totalCount) {
                    productCounters.forEach((counterElement) => {
                        counterElement.innerText = `${cartResponse.data.data.totalCount}`;
                    });
                }

                if (totalAmountElement && cartResponse.data.data?.totalPrice) {
                    totalAmountElement.innerText = formatPrice(cartResponse.data.data.totalPrice);
                }

                updateProductsHandlers();

                cartPopup?.classList.remove('empty');
            }
        } catch (error) {
            if (axios.isAxiosError(error) && error.response?.status === 500) {
                openDynamicBanner(errorMessage, true);
            }
        }
    }
};

const init = () => {
    const handleCartButtonClick = async (event: Event) => {
        event.stopPropagation();

        await loadCartData();

        if (cartPopup) {
            cartPopup.classList.add('active');
        }

        if (handlePreviousNotificationClose) {
            handlePreviousNotificationClose();
        }
    };

    const handleCartClose = () => {
        if (cartPopup) {
            cartPopup.classList.remove('active');
        }
    };

    const stopPropagationHandler = (event: Event) => {
        event.stopPropagation();
    };

    if (closePopupButton && cartPopup) {
        cartButtons.forEach((button) => {
            button.addEventListener('click', handleCartButtonClick);
        });
        cartPopup.addEventListener('click', stopPropagationHandler);
        closePopupButton.addEventListener('click', handleCartClose);
        document.addEventListener('click', handleCartClose);
    }
};

export async function addProductToCardWithBuyBtn(this: HTMLElement, event: Event) {
    event.preventDefault();
    event.stopPropagation();

    return;
}

const initForPage = () => {
    const products = document.querySelectorAll('.js-product');

    products.forEach((product) => {
        const buyButton = product.querySelector<HTMLElement>('.js-product-buy-btn');

        if (buyButton) {
            pageHandlers.set(buyButton, addProductToCardWithBuyBtn);
        }
    });
};

const destroyForPage = () => {
    pageHandlers.clear();
};

const _module = { init, initForPage, destroyForPage };

export default _module;
